import { MobileItem } from '@client/types'

export const insertBannerToFeed = (
  blocks: MobileItem[],
  withBanners: boolean
): MobileItem[] => {
  if (!withBanners) {
    return blocks
  }

  const preparedBlocks: MobileItem[] = []
  let nextBannerIndex = 2

  blocks.forEach((block: MobileItem, i) => {
    if (i === nextBannerIndex) {
      preparedBlocks.push({
        key: 'dfp_banner',
        keys: [],
        origin: ['---']
      })
      nextBannerIndex += 10
    }

    preparedBlocks.push(block)
  })

  return preparedBlocks
}
