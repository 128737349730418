export const locale = {
  beet_subscription: {
    tag: 'Weekly newsletter',
    captcha: `Protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp;and&nbsp;<a href="https://policies.google.com/terms">Terms of Service</a> apply.`,
    action: 'Subscribe',
    label: 'Subscribe',
    title: 'Sign up for&nbsp;The&nbsp;Beet',
    placeholder: 'Email',
    second_title:
      'Underreported&nbsp;stories. Fresh&nbsp;perspectives. From&nbsp;Budapest to&nbsp;Bishkek.',
    success_message:
      'Excellent! Now&nbsp;you’ve&nbsp;got&nbsp;to&nbsp;verify that&nbsp;email address.',
    wrong_email_message: 'Something went wrong! Try again',
    something_wrong: `Something went wrong! Please&nbsp;contact&nbsp;us&nbsp;at&nbsp;<a href="mailto:beet@meduza.io">beet@meduza.io</a>,<br/>and we'll get you signed up for our newsletter.`
  }
}
