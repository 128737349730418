import { getIsFetchInitiated } from '@selectors/appSelectors'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { emailLogin } from '@store/CurrentUser/currentUserActions'
import { CurrentUserState } from '@store/CurrentUser/currentUserReducer'
import { showModal } from '@store/Modal/modalActions'
import { handleError } from '@utils/handleError'
import { sharePopup } from '@utils/sharePopup'
import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import { ShareServices } from 'types/common'

import * as api from '../api'

import {
  callToAction,
  fetchBanners,
  fetchExchangeRates,
  fetchPopoverBanner,
  sendStat
} from './appActions'

/*
 Вызывает окно модалки если юзер решил отправить свой текст
 или открыает окно шера при нажатии на кнопки шера в материале
 */

export function* callToActionSaga({
  payload: { service }
}: ReturnType<typeof callToAction>) {
  if (service === 'reaction') {
    yield put(
      showModal({
        type: 'idea'
      })
    )
  }

  const {
    last_opened_material,
    last_opened_special
  }: CurrentUserState = yield select(getCurrentUser)
  if (last_opened_material) {
    yield call(sharePopup, service as ShareServices, last_opened_material)
  } else if (last_opened_special) {
    yield call(sharePopup, service as ShareServices, last_opened_special)
  }
}

/*
Отправляет аналитику в апи
*/

export function* sendStatSaga({
  payload
}: ReturnType<typeof sendStat.request>) {
  try {
    yield call(api.sendStat, payload)
    yield put(sendStat.success())
  } catch (err) {
    yield call(handleError, err, sendStat.failure.type)
    yield put(sendStat.failure())
  }
}

/*
Открывает встречающую модалку при логине через email
*/

export function* LoginEmailSuccessSaga() {
  yield put(
    showModal({
      type: 'postAuth',
      coordinates: 0
    })
  )
}

/*
 Запрашивает курс валют в апи
 */

export function* fetchExchangeRatesSaga() {
  yield delay(100)
  const isFetchInitiated = yield select(getIsFetchInitiated)
  if (isFetchInitiated) {
    try {
      const response = yield call(api.fetchExchangeRates)
      yield put(fetchExchangeRates.success({ response }))
    } catch (err) {
      yield call(handleError, err, fetchExchangeRates.failure.type)
      yield put(fetchExchangeRates.failure())
    }
  }
}

export function* fetchBannersSaga() {
  try {
    const response = yield call(api.fetchBanners)
    yield put(fetchBanners.success({ response }))
  } catch (err) {
    yield call(handleError, err, fetchBanners.failure.type)
    yield put(fetchBanners.failure())
  }
}

/*
 Запрос баннера для попапа
 */

export function* fetchPopoverBannerSaga() {
  try {
    const response = yield call(api.fetchPopoverBanner)
    yield put(fetchPopoverBanner.success({ response }))
  } catch (err) {
    yield call(handleError, err, fetchPopoverBanner.failure.type)
    yield put(fetchPopoverBanner.failure())
  }
}

export default function* appSaga() {
  yield all([
    takeEvery(callToAction.type, callToActionSaga),
    takeEvery(emailLogin.type, LoginEmailSuccessSaga),
    takeEvery(sendStat.request.type, sendStatSaga),
    takeLatest(fetchExchangeRates.request.type, fetchExchangeRatesSaga),
    takeLatest(fetchBanners.request.type, fetchBannersSaga),
    takeLatest(fetchPopoverBanner.request.type, fetchPopoverBannerSaga)
  ])
}
