import React from 'react'
import { useSelector } from 'react-redux'
import { Chapters } from '@client/components/Common/Chapters'
import { Block, CardsMaterialContent } from '@client/types'
import { generateChapters } from '@client/utils/generateChapters'
import { BookmarkIcon } from '@common/BookmarkIcon'
import { useLightbox } from '@common/Lightbox'
import { RenderBlocks } from '@common/RenderBlocks'
import { getLang } from '@selectors/currentUserSelectors'
import { makeClassName } from '@utils/makeClassName'

import styles from './CardMaterial.module.css'

export interface CardMaterialProps {
  material: CardsMaterialContent
}

export const CardMaterial: React.FC<CardMaterialProps> = ({
  material: {
    image,
    url,
    theme,
    content: { head, cards },
    table_of_contents,
    layout
  }
}) => {
  const lightBox = useLightbox()

  const lang = useSelector(getLang)

  const renderCard = (card: { blocks: Block[] }, index: number) => {
    return (
      <div key={`card${index}`} className={styles.card}>
        {card.blocks.map((block) => (
          <RenderBlocks
            url={url}
            key={block.id}
            block={block}
            styleContext={['card', 'isInCard']}
          />
        ))}
      </div>
    )
  }

  const isDark =
    theme?.background_color === '26, 26, 26' || !theme?.background_color

  const metaTheme = isDark ? 'light' : ''

  const cssProps = {
    '--bgColor': `rgb(${theme?.background_color || '26, 26, 26'})`,
    '--textColor': `rgb(${theme?.text_color || '255, 255, 255'})`
  } as React.CSSProperties

  const chapterData = table_of_contents?.enabled
    ? generateChapters(cards, layout)
    : null

  return (
    <div className={styles.root} style={cssProps}>
      <div className={styles.head}>
        <div className={styles.image}>
          {image && 'large_url' in image && (
            <img src={image.large_url} alt="" />
          )}
        </div>

        <div className={styles.header}>
          {head.map((block) => (
            <RenderBlocks
              url={url}
              key={block.id}
              block={block}
              lightBox={lightBox}
              styleContext={['card', 'isInCard', metaTheme]}
            />
          ))}
          <div className={styles.bookmark}>
            <BookmarkIcon url={url} />
          </div>
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.main}>
          {table_of_contents?.enabled && (
            <Chapters
              block={chapterData}
              display="center"
              type="card"
              lang={lang}
            />
          )}
          <div className={styles.content}>{cards && cards.map(renderCard)}</div>
        </div>
      </div>
    </div>
  )
}
