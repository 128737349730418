import React from 'react'
import {Helmet} from 'react-helmet-async'
import { MetaScreenTitles } from '@client/constants'
import { Lang, MaterialContent, Og } from '@client/types'

const getTitle = (material: MaterialContent) => {
  const { second_title: secondTitle, one_picture: onePicture } = material
  const { title } = material

  const SENTENCE_TERMINATORS = ['.', '?', '!']
  let materialTitle = title

  if (secondTitle && !onePicture) {
    materialTitle +=
      SENTENCE_TERMINATORS.indexOf(title.charAt(title.length - 1)) > -1
        ? ` ${secondTitle}`
        : `: ${secondTitle}`
  }

  return materialTitle
}

/*
 * Сложный и нуждающийся в додумывании универсальный компонент мета-данных для og-тегов и прочего
 */

interface MetaProps {
  material?: MaterialContent
  lang: Lang
  screen?: string
  og?: Og
}

export const Meta: React.FC<MetaProps> = ({ material, lang, og, screen }) => {
  const title = material
    ? `${material.og ? material.og.title : getTitle(material)}`
    : `${
        (og && og.title) || (screen && MetaScreenTitles[lang][screen.slice(1)])
      }`

  const hasOg = og && og.url
  const shareImage = material
    ? material.og.image
    : screen && og
    ? og && og.image
    : null

  const canonicalUrl = screen
    ? hasOg
      ? hasOg
      : `https://meduza.io/${screen === 'news' ? '' : screen}`
    : material && material.og.url

  return (
    <Helmet titleTemplate="%s">
      <html prefix="og:http://ogp.me/ns#" lang={lang === 'en' ? 'en' : 'ru'} />
      <title>{og ? og.title : title}</title>
      {screen && [
        <meta
          key="description"
          name="description"
          content={
            /* eslint-disable no-nested-ternary */
            og && og.description
              ? og.description
              : lang === 'en'
              ? 'The Real Russia. Today.'
              : 'Ничего лишнего, только факты'
            /* eslint-enable no-nested-ternary */
          }
        />,
        <meta
          key="keywords"
          name="keywords"
          content={
            /* eslint-disable no-nested-ternary */
            og && og.keywords
              ? og.keywords
              : lang === 'en'
              ? 'News, report, investigation, interview, video, quiz, game, photo series, conflict, war, what we know, brief, wrap, opinion, like it or not, Russia, Ukraine, Syria, Putin, president, Poroshenko, ruble, economy, oil, gas'
              : 'новости, политика, расследование, репортаж, интервью, реакция, исследование, опрос, тест, президент, приговор, доллар, нефть, экономика, цены, средства, информация, компания, фото, видео, украина, сепаратисты, война, краткий пересказ, бестселлер'
            /* eslint-enable no-nested-ternary */
          }
        />
      ]}
      {material && <title>{title}</title>}
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:site_name" content="Meduza" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:locale" content={lang === 'ru' ? 'ru_RU' : 'en_US'} />
      <meta property="fb:pages" content="375587425929352" />
      <meta property="fb:app_id" content="944674498882174" />
      <meta
        name="facebook-domain-verification"
        content="oy14jw4lf4va7ny1oby3u57hnhn03t"
      />
      <meta property="al:web:should_fallback" content="true" />
      <meta name="incorrect-apple-itunes-app" content="app-id=921508170" />
      <meta
        name="incorrect-google-play-app"
        content="app-id=io.meduza.android"
      />
      <meta name="twitter:site" content="@meduzaproject" />
      <meta name="twitter:title" content={title} />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-64x64.png"
        sizes="64x64"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-16x16.png"
        sizes="16x16"
      />
      <link rel="apple-touch-icon" href="/apple-touch-icon-1024.png" />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/apple-touch-icon-76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-touch-icon-120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/apple-touch-icon-152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon-180.png"
      />
      {material && [
        <meta key="og:type" property="og:type" content="article" />,
        <meta
          key="twitter:card"
          name="twitter:card"
          content={
            ['video'].indexOf(material.layout) === -1
              ? 'summary_large_image'
              : 'player'
          }
        />,
        <meta
          key="al:ios:url"
          property="al:ios:url"
          content={`meduza://?id=${material.url}`}
        />,
        <meta
          key="al:android:url"
          property="al:android:url"
          content={`meduza://?id=${material.url}`}
        />
      ]}
      <meta
        key="al:android:package"
        property="al:android:package"
        content="io.meduza.android"
      />
      {material && material.og && material.og.amp && (
        <link
          key="amphtml"
          rel="amphtml"
          href={`https://meduza.io/amp/${material.url}`}
        />
      )}
      {material && material.og && material.og.keywords && (
        <meta
          key="material-keywords"
          name="keywords"
          content={material.og.keywords}
        />
      )}
      {material && material.layout === 'podcast' && (
        <link
          type="application/rss+xml"
          rel="alternate"
          title={title}
          href={material.podcast.rss_url}
        />
      )}
      {material && material.layout !== 'podcast' && (
        <link
          rel="alternate"
          type="application/rss+xml"
          title={title}
          href={`${lang === 'en' ? '/en/rss/all' : '/rss/all'}`}
        />
      )}
      {material &&
        material.layout !== 'podcast' &&
        lang === 'ru' && [
          <link
            key="alternate-rss-news"
            rel="alternate"
            type="application/rss+xml"
            title="Meduza — Новости"
            href="/rss/news"
          />,
          <link
            key="alternate-rss-fun"
            rel="alternate"
            type="application/rss+xml"
            title="Meduza — Шапито"
            href="/rss/fun"
          />
        ]}
      {material &&
        material.og &&
        material.og.description && [
          <meta
            key="description"
            name="description"
            content={material.og.description}
          />,
          <meta
            key="og:description"
            property="og:description"
            content={material.og.description}
          />,
          <meta
            key="twitter:description"
            name="twitter:description"
            content={material.og.description}
          />
        ]}
      {material &&
        material.layout === 'episode' && [
          <meta
            key="episode:twitter:player:width"
            name="twitter:player:width"
            content="480"
          />,
          <meta
            key="episode:twitter:player:height"
            name="twitter:player:height"
            content="560"
          />,
          <meta
            key="episode:twitter:player"
            name="twitter:player"
            content={`https://meduza.io/${material.url}?embedded=true`}
          />,
          <meta
            key="episode:twitter:player:stream"
            name="twitter:player:stream"
            content={`'https://meduza.io${material.audio!.mp3_url.replace(
              '?client=native',
              '?client=twitter'
            )}`}
          />,
          <meta
            key="episode:twitter:player:stream:content_type"
            name="twitter:player:stream:content_type"
            content="audio/mpeg"
          />
        ]}
      {shareImage && [
        <link key="image_src" rel="image_src" href={shareImage} />,
        <meta key="og:image" property="og:image" content={shareImage} />,
        <meta key="og:image:width" property="og:image:width" content="1200" />,
        <meta key="og:image:height" property="og:image:height" content="630" />,
        <meta key="twitter:image" name="twitter:image" content={shareImage} />,
        <meta
          key="vk:image"
          property="vk:image"
          content={shareImage.replace('/share/', '/vk_share/')}
        />
      ]}
    </Helmet>
  )
}
