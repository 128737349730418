/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetDaily } from '@client/store/CurrentUser/currentUserActions'
import { makeClassName } from '@utils/makeClassName'
import { validateEmail } from '@utils/validateEmail'

import { BeetLogo } from '../../RenderBlocks/BeetSubscription/BeetLogo'

import { locale } from './locale'
import { SignalLogo } from './SignalLogo'

import styles from './FooterBlock.module.css'

interface BriefSubscriptionProps {
  handleSubscribeToDaily: any
  handleSubscribe: any
  daily: any
  email: any
  handleInput: any
  error: any
  lang: 'ru' | 'en'
}

export const FooterBlock: React.FC<BriefSubscriptionProps> = ({
  handleSubscribeToDaily,
  handleSubscribe,
  daily,
  email,
  handleInput,
  error,
  lang
}) => {
  const local = locale[lang]
  const localNew = locale.new

  const dispatch = useDispatch()

  const renderSuccessMessage = (message: string, email: string) => {
    const success = email ? message.replace('{USER_EMAIL}', email) : message

    return (
      <div className={styles.success}>
        <p dangerouslySetInnerHTML={{ __html: success }} />
      </div>
    )
  }

  const [flag, setFlag] = useState(false)

  const handleFlag = () => {
    setFlag(!flag)

    dispatch(resetDaily())
  }

  return (
    <div
      className={makeClassName([
        [[styles.root], true],
        [[styles.isSuccess], email && daily.subscribed]
      ])}
    >
      <div className={styles.content}>
        {lang === 'en' && local.tag && (
          <div className={styles.toggleWrapper}>
            <h2 className={styles.tag} style={{ marginBottom: '0px' }}>
              {local.tag}
            </h2>
            {/* Toggle Switch Component starts */}
            <div className={styles.subscriptionToggle}>
              <input
                type="radio"
                id="subscription-toggle-weekly"
                name="subscription"
                value="weekly"
                checked={!flag}
                onChange={handleFlag}
              />
              <label htmlFor="subscription-toggle-weekly">Weekly</label>

              <input
                type="radio"
                id="subscription-toggle-daily"
                name="subscription"
                value="daily"
                onChange={handleFlag}
              />
              <label htmlFor="subscription-toggle-daily">Daily</label>
            </div>
            {/* Toggle Switch Component ends */}
          </div>
        )}

        {lang === 'ru' && local.tag && (
          <h2 className={styles.tag}>{local.tag}</h2>
        )}

        {flag && local.title && <h3 className={styles.title}>{local.title}</h3>}
        {lang === 'en' && !flag && localNew.hasLogo && (
          <div className={styles.imageBeet}>
            <BeetLogo />
          </div>
        )}
        {lang === 'ru' && local.hasLogo && (
          <div className={styles.image}>
            <SignalLogo />
          </div>
        )}

        {lang === 'ru' && (
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: local.text }}
          />
        )}
        {lang === 'en' && (
          <div
            className={styles.textEng}
            dangerouslySetInnerHTML={
              flag ? { __html: local.text } : { __html: localNew.text }
            }
          />
        )}

        <form
          className={lang === 'ru' ? styles.subscribe : styles.subscribeEng}
          //если российская локаль, то подписка идет на Сигнал
          //если англиская локаль, то подписываемся на рассылку в зависимости от флага (the Beet или Real Russia)
          onSubmit={
            lang === 'ru'
              ? handleSubscribeToDaily
              : !flag
              ? handleSubscribe
              : handleSubscribeToDaily
          }
        >
          <div className={styles.form}>
            <div
              className={makeClassName([
                [[styles.field], true],
                [styles.invalid, (email && error) || (email && daily.error)]
              ])}
            >
              <label htmlFor="footer-email">
                <span>Email</span>
              </label>
              <input
                className={styles.input}
                type="email"
                defaultValue={email}
                onInput={handleInput}
                id="footer-email"
                placeholder="Email"
              />
              {email && error && (
                <span className={styles.message}>
                  Ошибка! Попробуйте еще раз
                </span>
              )}

              {email && !error && daily.error && (
                <span
                  className={styles.message}
                  dangerouslySetInnerHTML={{ __html: local.something_wrong }}
                />
              )}
            </div>

            <button
              className={styles.button}
              disabled={!validateEmail(email)}
              type="submit"
              aria-label={local.cta}
            >
              <span className={styles.buttonLabel}>{local.cta}</span>
              <span className={styles.buttonIcon}>
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 16"
                >
                  <path
                    d="M24 8.005a1.21 1.21 0 0 0-.333-.847L17.39.372a1.232 1.232 0 0 0-.361-.279.9.9 0 0 0-.4-.093.972.972 0 0 0-.734.31 1.1 1.1 0 0 0-.285.775c0 .165.025.316.076.454.057.13.133.248.228.351l2.134 2.345L21.6 7.757l.333-.671-3.304-.217H1.057c-.317 0-.574.107-.771.32-.19.207-.286.479-.286.816 0 .33.095.603.286.816.197.207.454.31.771.31H18.63l3.304-.207-.333-.66-3.552 3.511-2.134 2.335a1.214 1.214 0 0 0-.228.361c-.051.138-.076.286-.076.444 0 .31.095.569.285.775.197.207.442.31.734.31.279 0 .53-.117.752-.351l6.286-6.797A1.21 1.21 0 0 0 24 8.005Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
          </div>

          {email &&
            daily.subscribed &&
            renderSuccessMessage(local.success_message, email)}
        </form>
      </div>
    </div>
  )
}
