import React, { memo, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import { DFPBanner } from '@client/components/Common/DFPBanner'
import { BannerTypes, DFPCodes } from '@client/constants'
import { MaterialContent, RootState } from '@client/types'
import { Skeleton } from '@common/Skeleton'
import { Feed } from '@routes/Screen/Feed'
import { Grid } from '@routes/Screen/Grid'
import { getIsMobile } from '@selectors/appSelectors'
import { getCurrentUnderTheSunScreen } from '@selectors/materialsSelectors'
import equal from 'fast-deep-equal'

import styles from './UnderTheSun.module.css'

/*
Компонент для рендера сетки/ленты блоков материалов под материалом
*/

interface UnderTheSunProps {
  material: MaterialContent
  lang: 'ru' | 'en'
}

export const UnderTheSunComponent: React.FC<UnderTheSunProps> = ({
  material: { url, banners },
  lang
}) => {
  const isMobile = useSelector(getIsMobile)
  const screen = useSelector(
    (state: RootState) => getCurrentUnderTheSunScreen(state, url),
    equal
  )
  const [visible, setVisible] = useState(false)

  const [ref, inView] = useInView({
    threshold: 0
  })

  useEffect(() => {
    if (inView && !visible) {
      setVisible(true)
    }
  }, [inView, visible])

  const emergencyDisableDFP = false

  return !screen ? (
    <Skeleton styleContext="isInFeed" />
  ) : (
    <div className={styles.root} ref={ref}>
      {!emergencyDisableDFP && lang === 'en' && banners && banners.bottom && (
        <div className={styles.banner}>
          <DFPBanner
            id={DFPCodes.DESKTOP_AFTER_MATERIAL}
            mobileId={DFPCodes.MOBILE_AFTER_MATERIAL}
            key={DFPCodes.DESKTOP_AFTER_MATERIAL}
            type={BannerTypes.AFTER_MATERIAL}
          />
        </div>
      )}

      {screen &&
        visible &&
        (isMobile ? (
          <Feed blocks={screen.mobile} />
        ) : (
          <Grid
            containers={screen.desktop}
            screenType="grid"
            currentUrl={url}
          />
        ))}
    </div>
  )
}

export const UnderTheSun = memo(UnderTheSunComponent)

UnderTheSun.whyDidYouRender = true
