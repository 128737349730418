import React from 'react'

export const IconX = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path
      d="M19.313 8.18h2.3l-5.025 5.776 5.912 7.86h-4.629l-3.625-4.767-4.148 4.767H7.796l5.375-6.178L7.5 8.18h4.746l3.277 4.357 3.79-4.357Zm-.807 12.251h1.275l-8.227-10.94h-1.368l8.32 10.94Z"
      fill="#fff"
    />
  </svg>
)

export const IconFacebook = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path
      d="M15.844 25H12.63v-8.62H10v-3.348h2.55c.214-2.268-.389-4.727 1.689-6.257 1.048-.773 2.625-.966 4.761-.588v2.977c-.579 0-1.128-.02-1.675.004-1.029.047-1.423.447-1.485 1.55-.04.71-.007 1.424-.007 2.24h3.068l-.381 3.362h-2.676V25Z"
      fill="#fff"
    />
  </svg>
)

export const IconLinkedin = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path
      d="M21.999 21h-3.101c0-1.101.008-2.18-.003-3.257-.008-.79-.019-1.581-.071-2.37-.055-.823-.488-1.377-1.416-1.436-.908-.058-1.608.284-1.818 1.157-.137.569-.147 1.17-.156 1.76-.021 1.345-.006 2.692-.006 4.11h-3.053v-9.338h2.833l.07.761c.955-.34 1.904-.901 2.9-.99 2.246-.199 3.529.943 3.773 3.086.052.457.045.921.046 1.383C22.002 17.544 22 19.22 22 21ZM10.624 21H8v-9.625h2.625V21ZM10.625 8.317c-.004.696-.627 1.308-1.33 1.308A1.305 1.305 0 0 1 8 8.307 1.305 1.305 0 0 1 9.306 7c.708.004 1.323.618 1.319 1.317Z"
      fill="#fff"
    />
  </svg>
)

export const IconReddit = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path
      d="M17.426 8.636c.207.895.994 1.562 1.934 1.562 1.098 0 1.987-.908 1.987-2.029 0-1.12-.89-2.028-1.987-2.028-.96 0-1.76.694-1.946 1.617-1.608.176-2.863 1.568-2.863 3.254v.01c-1.748.076-3.344.584-4.61 1.385a2.735 2.735 0 0 0-1.703-.593c-1.538 0-2.785 1.272-2.785 2.843 0 1.14.656 2.12 1.604 2.574.092 3.3 3.615 5.955 7.948 5.955s7.861-2.657 7.949-5.96a2.848 2.848 0 0 0 1.59-2.568c0-1.57-1.247-2.843-2.785-2.843-.638 0-1.226.22-1.696.589-1.278-.808-2.892-1.316-4.657-1.383v-.008c0-1.208.88-2.21 2.02-2.375v-.002Zm-7.598 7.825c.047-1.031.718-1.822 1.498-1.822s1.376.836 1.33 1.867c-.047 1.03-.63 1.405-1.41 1.405-.781 0-1.464-.42-1.418-1.45Zm8.857-1.822c.781 0 1.452.79 1.498 1.822.046 1.03-.638 1.45-1.418 1.45-.78 0-1.363-.373-1.41-1.405-.046-1.031.55-1.867 1.33-1.867Zm-.928 4.207c.147.015.24.17.183.31-.48 1.17-1.613 1.993-2.935 1.993s-2.454-.823-2.935-1.994a.225.225 0 0 1 .183-.309 26.951 26.951 0 0 1 2.752-.137c.968 0 1.894.049 2.752.137Z"
      fill="#fff"
    />
  </svg>
)

export const IconEmail = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <rect
      x="7"
      y="10"
      width="16"
      height="11"
      rx="1"
      fill="#fff"
      stroke="#fff"
      strokeWidth="1.7"
    />
    <path
      d="m25 22-5-4-2.5-2M5 22l5-4 2.5-2M5 10l8.75 7a2 2 0 0 0 2.5 0L25 10"
      stroke="#D9D9D9"
      strokeWidth="1.7"
    />
  </svg>
)

export const IconCopy = () => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path
      d="M13.695 16.305c1.655 1.654 4.263 1.737 5.816.184l2.4-2.4c1.553-1.553 1.47-4.16-.184-5.816-1.655-1.654-4.263-1.737-5.816-.184l-1.768 1.768"
      stroke="#fff"
      strokeWidth="1.7"
      strokeLinecap="round"
    />
    <path
      d="M16.282 13.718c-1.655-1.655-4.263-1.737-5.816-.184l-2.469 2.469c-1.547 1.547-1.464 4.155.19 5.81 1.655 1.654 4.263 1.737 5.817.184l1.837-1.838"
      stroke="#fff"
      strokeWidth="1.7"
      strokeLinecap="round"
    />
  </svg>
)
