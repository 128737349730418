import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { BannerTypes } from '@client/constants'
import { getIsMobile } from '@client/selectors/appSelectors'
import { BannerType } from '@client/types'
import { makeClassName } from '@utils/makeClassName'

import styles from './DFPBanner.module.css'

/*
 * Универсальный баннер
 */

interface DFPBannerProps {
  id: string
  mobileId?: string
  type: BannerType
  styleContext?: string
}

export const DFPBannerComponent: React.FC<DFPBannerProps> = ({
  id,
  mobileId,
  type,
  styleContext
}) => {
  const isMobile = useSelector(getIsMobile)

  return (
    <div
      className={makeClassName([
        [[styles.root], true],
        [
          !!styleContext && [styles[styleContext]],
          !!styleContext && styles[styleContext]
        ],
        [[styles[type.toLowerCase()]], !!type && styles[type.toLowerCase()]]
      ])}
    >
      <div className={styles.container}>
        {type === BannerTypes.SIDE && (
          <div className={styles.header}>Advertising</div>
        )}

        <div className={styles.body}>
          {isMobile && mobileId && (
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: `<pgs-ad data-pg-ad-spot=${mobileId}></pgs-ad>`
              }}
            />
          )}

          {(!isMobile || !mobileId) && (
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: `<pgs-ad data-pg-ad-spot=${id}></pgs-ad>`
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export const DFPBanner = memo(DFPBannerComponent)
