import { BannerType, ShareServices } from '@client/types'

export const MDZ_PN_TOKEN_KEY = 'mdzFirebasePushToken'
export const MDZ_AUTH_FIREBASE_TOKEN_KEY = 'mdzAuthFirebaseToken'
export const MDZ_DEFAULT_FIREBASE_TOPIC = 'breaking_news_js'
export const MDZ_FIREBASE_USER_DATA = 'mdzFirebaseUserDataNew'
export const FIREBASE_VAPID_KEY =
  'BOYOnObd_wXJAxFtZhqyOnfIDndfMZny1oQ4FSaovGMyAwzjY4pSeklHsS6-fxBXDGtwf38cCY74U_4jtpi3F6U'

export const FIREBASE_FUNCTIONS = {
  SUBSCRIBE_TO_PLAN: 'subscribeToPlan',
  DONATE_ONCE: 'donateOnce',
  UNSUBSCRIBE: 'deleteSubscription',
  MERGE_USERS: 'mergeUsers',
  SEND_MESSAGE: 'sendMessage',
  DELETE_USER: 'deleteUser'
}

export const LIVE_SLIDES_ON_PAGE = 30

export enum ScreenViewType {
  LIST = 'LIST',
  GRID = 'GRID'
}

export const MediaQuerySizes = {
  MOBILE: 512,
  PORTRAIT_TABLET: 768,
  LANDSCAPE_TABLET: 1024,
  DESKTOP: 1010,
  WIDE_DESKTOP: 1200
}

export const UIElementsSizes = {
  TOPBAR_HEIGHT: 45,
  TOPBAR_GAP: 15
}

export const LOGIN_TYPES = {
  GOOGLE: 'google',
  EMAIL: 'email-link',
  ANONYMOUS: 'anonymous'
}

export const REQUEST_STATUS = {
  SUCCESS: 'success',
  LOADING: 'loading',
  FAILURE: 'failure',
  NONE: 'none'
}

export const RE_CAPTCHA_KEY = '6LcM0iEfAAAAABz-lMzNTBlz9P1H-5yeCkeJJhJD'

export const MetaScreenTitles: Record<string, Record<string, string>> = {
  ru: {
    news: 'Новости — Meduza',
    articles: 'Истории — Meduza',
    razbor: 'Разбор — Meduza',
    games: 'Игры — Meduza',
    shapito: 'Шапито — Meduza',
    podcasts: 'Подкасты — Meduza',
    special: 'Meduza'
  },
  en: {
    news: 'Meduza'
  }
}

export const SCREENS = [
  '/',
  '/articles',
  '/cards',
  '/razbor',
  '/games',
  '/shapito',
  '/podcasts'
]

export const DFPCodes: Record<string, string> = {
  DESKTOP_AFT: 'div-gpt-ad-en-meduzaio41765',
  DESKTOP_STICKY: 'div-gpt-ad-en-meduzaio41766',
  DESKTOP_MAIN_ATF: 'div-gpt-ad-en-meduzaio41767',
  DESKTOP_MAIN_IN_CONTENT: 'div-gpt-ad-en-meduzaio41768',
  DESKTOP_NEWS_FEED: 'div-gpt-ad-en-meduzaio41769',
  DESKTOP_AFTER_MATERIAL: 'div-gpt-ad-en-meduzaio41770',
  DESKTOP_LONGREAD_BANNER: 'div-gpt-ad-en-meduzaio41771',
  MOBILE_TOP_BANNER: 'div-gpt-ad-en-meduzaio41772',
  MOBILE_IN_CONTENT: 'div-gpt-ad-en-meduzaio41773',
  MOBILE_AFTER_MATERIAL: 'div-gpt-ad-en-meduzaio41774'
}

export const BannerTypes: Record<string, BannerType> = {
  TOP: 'TOP',
  AFTER_MATERIAL: 'AFTER_MATERIAL',
  MAIN_IN_CONTENT: 'MAIN_IN_CONTENT',
  IN_CONTENT: 'IN_CONTENT',
  WITHIN: 'WITHIN',
  SIDE: 'SIDE'
}

// Exponential backoff for live saga constants

export const INITIAL_RECONNECT_DELAY = 1000
export const RANDOM_DELAY_INTERVAL = 2000
export const BACKOFF_FACTOR = 1.5
export const MAX_RECONNECT_DELAY = 16000

// Кол-во эпизодов в подкасте на одной странице для пагинации
export const EPISODES_PER_PAGE = 25

// Стартовый номер страницы
export const EPISODES_START_PAGE = 1

// Дебаунс (делей) при поиске
export const SEARCH_DELAY = 1000

export const POPUP_BANNER_MATERIAL_TYPES = ['rich', 'card']
