import React, { memo } from 'react'
import { DFPBanner } from '@client/components/Common/DFPBanner'
import { BannerTypes, DFPCodes } from '@client/constants'
import { DesktopItem } from '@client/types'
import { Section } from '@routes/Screen/Section'
import { makeClassName } from '@utils/makeClassName'

import styles from './Grid.module.css'

interface GridProps {
  containers: DesktopItem[]
  withBanner?: boolean
  styleContext?: string[]
  screenType?: string
  currentUrl?: string
}

/*
 * Сетка блоков для экранов десктопа
 */

const GridComponent: React.FC<GridProps> = ({
  containers,
  styleContext = [],
  withBanner = false,
  currentUrl
}) => {
  return (
    <div
      className={makeClassName(
        [[styles.root, true]].concat(
          styleContext.map((ctx) => [styles[ctx], !!ctx && styles[ctx]])
        )
      )}
    >
      {containers.map((container, index) => {
        const bannerType = BannerTypes.MAIN_IN_CONTENT
        const bannerId =
          index === 0 ? DFPCodes.DESKTOP_MAIN_ATF : DFPCodes.DESKTOP_NEWS_FEED

        const shouldShowBanner = index === 0 || index === 2
        const emergencyDisableDFP = false

        return (
          <React.Fragment key={container.uuid}>
            <section className={styles.container}>
              {container.title && (
                <header className={styles.header}>
                  <h2>{container.title}</h2>
                </header>
              )}
              {container.sections &&
                container.sections.map((section) => (
                  <Section
                    blocks={section.blocks}
                    currentUrl={currentUrl}
                    key={section.uuid}
                  />
                ))}
            </section>
            {!emergencyDisableDFP && withBanner && shouldShowBanner && (
              <div className={styles.banner}>
                <DFPBanner id={bannerId} type={bannerType} />
              </div>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export const Grid = memo(GridComponent)

Grid.whyDidYouRender = true
